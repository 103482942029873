import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Polls {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Polls",
    subtitle: `Looking for an easy way to figure out where your workers want to go for the company Christmas party? Or maybe you need opinions on a new logo? Our Polls feature allows you to collect feedback on whatever topic you choose, while driving engagement and allowing your employees to take ownership of decisions made within your organization. `,
    featureName: "polls",
    rightButtonText: "Why Certificate Tracking Software?",
    rightButtonSrc: "https://www.youtube.com/watch?v=xE2yXvWB038",
    mockupImg: '/features/polls/polls-header.png'
  }

  const seo = {
    title: "Workhub | Free Polling Software for Workplace Voting Employees",
    description: "Engage employees & get company feedback by allowing your workers to vote on internal affairs like event scheduling, policies, lunch options & employee satisfaction with our secure Polls tool.",
    image: "/features/polls/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Gain Insights",
      para: "Poll your workforce on company issues like, “What make should the new fleet vehicles be?” or more light-hearted topics such as, “Where should the company Christmas party be?”",
    },
    {
      title: "Elevate Compliance",
      para: "Workers will want to weigh in on the more fun, extracurricular themed polls. Setting a minimum compliance level will encourage your workers to get up to date so they can participate.",
    },
    {
      title: "Share Results",
      para: "Choose to automatically send out results in a Bulletin when a poll ends or generate a bulletin draft on demand for completed polls. This includes a summary and graph of results.",
    },
    {
      title: "Track Responses and Participation",
      para: "Refer to the participation chart to review overall interaction with the poll. View and filter individual responses by position and location in the Worker Responses tab.",
    },
   
  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">
        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/polls/sketch-mockup-spolls-admin-1.png"
                    alt="Admin Computer"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                <h2>
                ‘If you don’t vote, you lose the right to complain’ 
                </h2>
                <p>
                Polls is one of our funnest components. You can use it to get feedback on what everyone wants for lunch on Fridays, or to ask more serious questions like, ‘What make should our new fleet vehicles be?’ However, it can also be a sneakily incentivizing tool for driving up compliance and ensuring workers complete their required tasks. Let’s say that Bob has very strong opinions on whether pineapple belongs on pizza but only has a 40% compliance score - you have the option to adjust the settings so that only people above a certain percentage can participate. Suddenly, Bob who vehemently opposes pineapples on pizza has a 100% compliance rating and has also managed to sway the vote to Meat Lover’s by Friday’s lunch.  
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                <h2>Opinions count! </h2>

                <p>
                To setup a poll, all you have to do is choose your recipients and build a question. The poll can be set to release immediately or at a future date. Specify whether the poll accepts single, or multiple selections. A multiple selection question might be something like, ‘Which days would you be available for the Christmas party?’  
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInRight" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/polls/sketch-mockup-spolls-worker-1.png"
                    alt="Worker Mobile"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        {/* VIDEO CONTAINER */}

        <section className="feature-section video-section">
          <div>
            <div className="row video-row switch-children">

            <ReactWOW animation="fadeInLeft" delay="0.9s">
              <div className="video-bg-size" style={{width: '45%'}}>

              <div className="iframe-contain">
                <iframe width="700" height="500"src="https://www.youtube.com/embed/DvVwfatjueQ" frameborder="0" allowfullscreen></iframe>
              </div>
              
              </div>
              
              </ReactWOW>

              <div className="feature-text video-text" style={{width: '35%'}}>
                <h2>
                Getting down to the nitty-gritty. 
                </h2>

                <p>
                If you have several polls going at the same time you can organize them by setting a priority level. You can also allow people to vote anonymously. An example of where that might be applicable is when you want to gauge your employee’s satisfaction with a Mental Health and Wellness program, or how they perceived the success of a new product roll-out. Click the poll in the admin panel to view the participation rate and number of votes; or pop over to Workers Responses to view their selections.  
                </p>

              </div>
            </div>
          </div>
        </section>


        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>
      

      </main>
    </Layout>
  )
}
